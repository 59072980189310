.Toastify__toast--success {
  background-color: $colour_green;
}

.Toastify__toast--info {
  background-color: $colour_light_blue;
}

.Toastify__toast--error {
  background-color: $colour_red;
}
