@import url('https://fonts.googleapis.com/css?family=Assistant:400,600,700&display=swap');

body {
  font-family: 'Assistant', sans-serif;
}

.App {
  /* text-align: center; */
}

.ui.list .item.bsf-folder,
.ui.list .item.bsf-file {
  cursor: pointer;
}

.ui.table tbody tr td.selectable > a:not(.ui).timesheetBlock {
  display: inline;
  padding-right: 5px;
}
.ui.table tbody tr td.selectable > a:not(.ui).timesheetBlock:nth-child(2) {
  padding-left: 0px;
}

.ui.form {
  padding: 20px 0px;
}

.bsf-site-variation-personel {
  padding: 10px 0px;
}
.bsf-site-variation-personel table tbody tr:last-child td {
  border-top-color: rgba(0, 0, 0, 0.5);
}
.bsf-modal-image {
  margin: 0 auto;
  width: auto;
  height: 100%;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: normal;
}

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.customDatePicker {
  /* font-size: 13px;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px; */

  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s;
}

@media print {
  .no-print.no-print.no-print {
    display: none !important;
  }

  .no-print.no-print.no-print + * {
    margin-top: 0 !important;
  }
}
