$colour_primary: #00366e;
$colour_orange: #ff7927;
$colour_green: #59b415;
$colour_light_blue: #23b4d0;
$colour_pink: #df1995;
$colour_red: #e05913;

$colour_black: #1f1f1f;
$colour_dark_grey: #61656d;
$colour_medium_grey: #8d96a5;
$colour_border: #eaeaea;
$colour_light_grey: #f0f1f5;
$colour_white: #fff;
