@import url(https://fonts.googleapis.com/css?family=Assistant:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Assistant:400,600,700&display=swap);
.ui.header{font-family:'Assistant', sans-serif}h1.ui.header,h3.ui.header{font-weight:600}h2.ui.header,h4.ui.header{font-weight:700}.ui.button{font-family:'Assistant', sans-serif;font-weight:400;padding:15px 30px;border-radius:50px !important}.ui.button.primary{border:1px solid #00366e;background-color:#00366e}.ui.button.primary:hover,.ui.button.primary:focus{border-color:#004fa1;background-color:#004fa1}.ui.button.primary:active{background-color:#fff !important;color:#00366e}.ui.labeled.icon.button>.icon,.ui.labeled.icon.buttons>.button>.icon{background-color:rgba(0,0,0,0.15)}.Toastify__toast--success{background-color:#59b415}.Toastify__toast--info{background-color:#23b4d0}.Toastify__toast--error{background-color:#e05913}.ui.label.primary{background-color:#00366e !important;color:#fff}.ui.label.red{background-color:#e05913 !important}.ui.label.green{background-color:#59b415 !important}.ui.label.grey{background-color:#8d96a5 !important}.ui.toggle.checkbox input:checked ~ label::before{background-color:#00366e !important}body{margin:0;font-family:'Assistant', sans-serif;font-weight:400;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.ui.segment{border-color:#eaeaea}.ui.top.fixed.menu+.ui.fluid.container{padding-top:66px !important}

.prettyError{height:calc(100vh - 120px);display:flex !important}.prettyError h2 i{padding-bottom:25px !important;font-size:7rem !important}.prettyError .ui.centered.grid{flex:1 1;display:flex;justify-content:center;align-items:center}.prettyError .ui.centered.grid .column>div{justify-content:center;display:flex;padding:10px 0px}.prettyError .ui.centered.grid .button{max-width:300px;width:100%}

.doctorSubscription .doctorCardDetails{padding-top:10px}.doctorSubscription .doctorCardDetails>label{display:block;padding-bottom:20px}.doctorSubscription .doctorCardDetails .StripeElement{background-color:#fff;padding:10px;border:1px solid rgba(34,36,38,0.15);border-radius:3px}

.ui.segment.login-segment{max-width:500px;margin:0 auto}

body {
  font-family: 'Assistant', sans-serif;
}

.App {
  /* text-align: center; */
}

.ui.list .item.bsf-folder,
.ui.list .item.bsf-file {
  cursor: pointer;
}

.ui.table tbody tr td.selectable > a:not(.ui).timesheetBlock {
  display: inline;
  padding-right: 5px;
}
.ui.table tbody tr td.selectable > a:not(.ui).timesheetBlock:nth-child(2) {
  padding-left: 0px;
}

.ui.form {
  padding: 20px 0px;
}

.bsf-site-variation-personel {
  padding: 10px 0px;
}
.bsf-site-variation-personel table tbody tr:last-child td {
  border-top-color: rgba(0, 0, 0, 0.5);
}
.bsf-modal-image {
  margin: 0 auto;
  width: auto;
  height: 100%;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: normal;
}

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.customDatePicker {
  /* font-size: 13px;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px; */

  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s;
}

@media print {
  .no-print.no-print.no-print {
    display: none !important;
  }

  .no-print.no-print.no-print + * {
    margin-top: 0 !important;
  }
}

