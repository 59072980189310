@import url('https://fonts.googleapis.com/css?family=Assistant:400,600,700&display=swap');
@import 'styles/Colours.scss';
@import 'styles/Typeography.scss';
@import 'styles/Buttons.scss';
@import 'styles/Notifications.scss';
@import 'styles/Labels.scss';

body {
  margin: 0;
  font-family: 'Assistant', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui.segment {
  border-color: $colour_border;
}

.ui.top.fixed.menu + .ui.fluid.container {
  padding-top: 66px !important;
}
