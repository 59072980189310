.prettyError {
  height: calc(100vh - 120px);
  display: flex !important;

  h2 {
    i {
      padding-bottom: 25px !important;
      font-size: 7rem !important;
    }
  }

  .ui.centered.grid {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .column > div {
      justify-content: center;
      display: flex;

      padding: 10px 0px;
    }

    .button {
      max-width: 300px;
      width: 100%;
    }
  }
}
