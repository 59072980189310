.ui.button {
  font-family: 'Assistant', sans-serif;
  font-weight: 400;
  padding: 15px 30px;
  border-radius: 50px !important;

  &.primary {
    border: 1px solid $colour_primary;
    background-color: $colour_primary;

    &:hover,
    &:focus {
      border-color: lighten($colour_primary, 10%);
      background-color: lighten($colour_primary, 10%);
    }

    &:active {
      background-color: $colour_white !important;
      color: $colour_primary;
    }
  }
}

.ui.labeled.icon.button > .icon,
.ui.labeled.icon.buttons > .button > .icon {
  background-color: rgba(0, 0, 0, 0.15);
}
