.doctorSubscription {
  .doctorCardDetails {
    padding-top: 10px;

    & > label {
      display: block;
      padding-bottom: 20px;
    }

    .StripeElement {
      background-color: #fff;
      padding: 10px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 3px;
    }
  }
}
