.ui.label {
  &.primary {
    background-color: $colour_primary !important;
    color: $colour_white;
  }

  &.red {
    background-color: $colour_red !important;
  }

  &.green {
    background-color: $colour_green !important;
  }

  &.grey {
    background-color: $colour_medium_grey !important;
  }
}

.ui.toggle.checkbox input:checked ~ label::before {
  background-color: $colour_primary !important;
}
