.ui.header {
  font-family: 'Assistant', sans-serif;
}

h1,
h3 {
  &.ui.header {
    font-weight: 600;
  }
}

h2,
h4 {
  &.ui.header {
    font-weight: 700;
  }
}
